/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "sonic-form-20190122113859-hostingbucket-test",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://dqcjvbu1a0pb4.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-east-1:3ee0243f-767b-400e-98b6-a191a45e3409",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_owIf34YFC",
    "aws_user_pools_web_client_id": "m0h7nnaqohl6ouuq1nclpqdeq",
    "oauth": {},
    "aws_mobile_analytics_app_id": "5889f7bc6f224809af3f3b0f97654a03",
    "aws_mobile_analytics_app_region": "us-east-1",
    "aws_user_files_s3_bucket": "sonicform-test",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "sonicform",
            "endpoint": "https://102otfxrq4.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
